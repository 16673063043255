import React from "react"
import Layout from "../components/layout"

export default function RedundancyContactUs({ data, location }) {
  const seo = {
    metaTitle: "Contact us - Redundancy",
    metaDescription: "Fill in the form to contact us",
    url: location.pathname,
  }
  return (
    <Layout hideContactBar="true" seo={seo}>
      <div className="container mx-auto px-4 mb-12 mt-12 lg:px-12">
        <h1 className="font-sans font-bold text-4xl md:text-5xl lg:text-6xl mb-10 text-left">
          Contact us
        </h1>
        <p>Fill in the form below with your details and we'll be in touch.</p>

        <iframe
          id="typeform-full"
          width="100%"
          height="700px"
          frameborder="0"
          src="https://laskie9.typeform.com/to/nAUh85op"
        ></iframe>
      </div>
    </Layout>
  )
}
